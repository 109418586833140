// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-authors-tsx": () => import("./../../../src/pages/authors.tsx" /* webpackChunkName: "component---src-pages-authors-tsx" */),
  "component---src-pages-categories-tsx": () => import("./../../../src/pages/categories.tsx" /* webpackChunkName: "component---src-pages-categories-tsx" */),
  "component---src-pages-index-tsx": () => import("./../../../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-sitemap-tsx": () => import("./../../../src/pages/sitemap.tsx" /* webpackChunkName: "component---src-pages-sitemap-tsx" */),
  "component---src-templates-author-page-template-tsx": () => import("./../../../src/templates/AuthorPageTemplate.tsx" /* webpackChunkName: "component---src-templates-author-page-template-tsx" */),
  "component---src-templates-category-page-template-tsx": () => import("./../../../src/templates/CategoryPageTemplate.tsx" /* webpackChunkName: "component---src-templates-category-page-template-tsx" */),
  "component---src-templates-images-tale-page-template-tsx": () => import("./../../../src/templates/ImagesTalePageTemplate.tsx" /* webpackChunkName: "component---src-templates-images-tale-page-template-tsx" */),
  "component---src-templates-text-tale-page-template-tsx": () => import("./../../../src/templates/TextTalePageTemplate.tsx" /* webpackChunkName: "component---src-templates-text-tale-page-template-tsx" */),
  "component---src-templates-text-tales-list-template-tsx": () => import("./../../../src/templates/TextTalesListTemplate.tsx" /* webpackChunkName: "component---src-templates-text-tales-list-template-tsx" */)
}


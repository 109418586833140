// custom typefaces
import "typeface-montserrat"
import "typeface-merriweather"
// normalize CSS across browsers
import "./src/normalize.css"
// custom CSS styles
import "./src/style.css"

// Highlighting for code blocks
import "prismjs/themes/prism.css"

const addScript = url => {
    const script = document.createElement("script")
    script.src = url
    script.defer = true
    document.body.appendChild(script)
}

export const onRouteUpdate = ({ location }) => {
    if (location.pathname.includes('/tales/') && document.querySelector('.tale-page')) {
        function showActivePart(number) {
            const parts = document.querySelectorAll('.content > div');

            parts.forEach(function (part) {
                const sliceIndex = location.href.indexOf('#') > 0 ? location.href.indexOf('#') : 0;
                part.classList.remove('active');
                if (part.classList.contains(number)) {
                    part.classList.add('active');
                    window.location.replace(location.href.slice(0, sliceIndex) + '#' + number)
                    window.scrollTo({top: 0, behavior: 'smooth'});
                }
            });
        }
        const arrows = document.querySelectorAll('.parts-arrows a');

        //take hash from url and shop this part if exist
        if (!window.location.hash && arrows.length) {
            window.location.replace(location.href + '#1')
        }

        showActivePart(window.location.hash.replace('#', ''))

        arrows.forEach(function (arrow) {
            arrow.addEventListener('click', function (e) {
                e.preventDefault();
                showActivePart(arrow.dataset.part);
            });
        });
    }
}